<div class="pl-provider-assignments page-container">
  <div class="container-margins">
    <div class="header-container">
      <div class="assigments">
        <span class="header-tittle"> Assignment Marketplace </span>
        <div class="header-text">
          <div class="break-line-margin">
            A super cool place to pick and choose the assignments you want.
          </div>

          <ng-container *ngIf="!loading && !loadingAvailability">
            <span *ngIf="totalHoursProposed < maxWeeklyHours">
              Satisfied with your assignments?
              <span
                class="stop-proposals-text"
                (click)="updateMaxAvailableHours()"
                >Stop receiving proposals</span
              >
            </span>

            <span *ngIf="totalHoursProposed >= maxWeeklyHours">
              Need to adjust your requested hours?
              <a class="stop-proposals-text" [routerLink]="['/availability']"
                >Update your availability</a
              >
            </span>
          </ng-container>
        </div>
      </div>
      <div class="hours-card-container">
        <div class="hours-card">
          <div class="hours-number">
            {{ maxWeeklyHours | number : '1.0-2' }}
          </div>
          <div class="hours-type">Desired hours</div>
        </div>
        <div class="hours-card">
          <div class="hours-number">
            {{ totalHoursProposed | number : '1.0-2' }}
          </div>
          <div class="hours-type">Assigned hours</div>
        </div>
        <div class="hours-card">
          <div class="hours-number">
            {{ maxWeeklyHours - totalHoursProposed | number : '1.0-2' }}
          </div>
          <div class="hours-type">Remaining hours</div>
        </div>
      </div>
    </div>

    <div class="divider"></div>
    <div class="content-container cards-page-container">
      <div *ngIf="loading" class="padding-xlarge-tb">
        <pl-dot-loader></pl-dot-loader>
      </div>
      <div [style.text-align]="'center'">
        <div *ngIf="!loading && !hasAssignments" class="empty-assignments">
          You have no assignments at this time
        </div>
      </div>
      <div *ngIf="!loading && hasAssignments" class="cards lanes">
        <div
          class="lane-1 lane proposed"
          *ngIf="assignments.length"
          [@plFadeInAnimation]
        >
          <div class="card-container" style="display: flex">
            <div
              class="card"
              *ngFor="let assignment of assignments"
              [ngClass]="assignmentStatusClasses[assignment.status]"
            >
              <div class="assigment-proposed">
                <span
                  class="text-proposal-status"
                  [ngClass]="assignmentStatusClasses[assignment.status]"
                >
                  {{ assignment.status | plAssigmentStatus }}
                </span>
                <div
                  *ngIf="assignment.status === assigmentStatusEnum.INITIATED"
                  class="expires-on"
                >
                  EXPIRES ON
                  {{ assignment.expiration_time | date : 'medium' }}
                </div>
              </div>
              <div class="card-data org-name">
                <div class="org-name-text">
                  {{ assignment.orgName }}
                </div>
                <div class="service-line-text">
                  {{ assignment.main_service_line_title | plServiceLineTitle }}
                  {{ assignment.isESY ? '(ESY)' : '' }}
                </div>
                <div class="card-divider"></div>
              </div>
              <div class="scroll">
                <div class="space-to-scroll">
                  <pl-missing-requirements-message
                    [status]="assignment.status"
                    [unmetRequirements]="assignment.unmetRequirements"
                  ></pl-missing-requirements-message>
                  <div>
                    <div class="card-data sub">
                      <div class="label">Rate</div>
                      ${{ assignment.payRate | number : '1.2-2' }}
                    </div>
                    <div class="card-data sub">
                      <span class="label">Assignment type</span>
                      {{ assignment.assignment_type }}
                    </div>
                    <div class="card-data sub school-type">
                      <div class="label">School type</div>
                      {{ assignment.orgSchoolType | plCapitalizeFirst }}
                    </div>
                    <div
                      class="card-data sub"
                      *ngIf="assignment.specialties_label"
                    >
                      <span class="label">Specialty</span>
                      {{ assignment.specialties_label }}
                    </div>
                    <div class="card-data sub state">
                      <div class="label">State</div>
                      {{ assignment.state_full_name }}
                    </div>
                    <div
                      class="card-data sub timezone"
                      *ngIf="assignment.orgTimezone"
                    >
                      <span class="label">Time zone</span>
                      {{ assignment.orgTimezone }}
                    </div>
                    <div class="card-data sub est-dir-hours">
                      <span class="label">Estimated hours</span>
                      {{ assignment.estimatedHours | number : '1.1' }}
                    </div>
                    <div class="card-data sub">
                      <span class="label"
                        >First work day
                        <pl-icon
                          class="blue-medium"
                          matTooltip="This is the day you start billing for this assignment"
                          matTooltipPosition="above"
                          matTooltipClass="pl-tooltip"
                          [svg]="'info'"
                          [height]="12"
                          [width]="12"
                          [verticalAlign]="'-2px'"
                        >
                        </pl-icon>
                      </span>
                      {{ assignment.startDate | date : 'MM/dd/yyyy' }}
                    </div>
                    <div class="card-data sub">
                      <span class="label">Therapy start date</span>
                      {{
                        assignment.projected_therapy_start_date
                          | date : 'MM/dd/yyyy'
                      }}
                    </div>
                    <div class="card-data sub missing-req">
                      <span class="label">End date</span>
                      {{ assignment.endDate | date : 'MM/dd/yyyy' }}
                    </div>
                    <div class="card-data sub missing-req">
                      <span class="label">Presence contact</span>
                      {{ assignment.clinical_success_manager }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="action-buttons">
                <!-- TO DO REMOVE ngIf after the view details is implemented -->
                <button mat-raised-button color="primary" *ngIf="false">
                  View details
                </button>
                <ng-container
                  *ngIf="assignment.status === assigmentStatusEnum.INITIATED"
                >
                  <button
                    mat-stroked-button
                    color="primary"
                    type="button"
                    (click)="onClickDecline(assignment)"
                    [disabled]="saving"
                  >
                    Decline
                  </button>
                  <button
                    mat-flat-button
                    color="primary"
                    type="button"
                    (click)="onClickAccept(assignment)"
                    [disabled]="saving"
                  >
                    Accept
                  </button>
                </ng-container>
                <button
                  *ngIf="assignment.status === assigmentStatusEnum.RESERVED"
                  mat-stroked-button
                  color="primary"
                  type="button"
                  (click)="openContactClsmDialog(assignment)"
                  [disabled]="assignment.is_on_hold"
                >
                  {{
                    assignment.is_on_hold ? 'CLSM contacted' : 'Contact CLSM'
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
