import { PLProviderService } from '@modules/providers/pl-provider.service';

declare var require: any;

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import dayjs from 'dayjs';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
import { orderBy } from 'lodash';
import { forkJoin } from 'rxjs';
import { defaultIfEmpty, filter, first, switchMap } from 'rxjs/operators';
import { AppStore } from '@app/appstore.model';
import { PLProviderTypeCode } from '@common/enums';
import { PLTasksService, PLUtilService } from '@common/services';
import {
  selectCurrentUser,
  selectCurrentUserLoaded,
  selectIsW2User,
} from '@common/store';
import { environment } from '@environments/environment';
import * as PLOnboardingTasks from '@modules/dashboard/pl-dashboard/pl-onboarding-tasks/pl-onboarding-tasks';
import {
  PLHttpService,
  PLUrlsService,
  PLMayService,
  PLApiBillingCodesService,
  PLGraphQLService,
  PLModalService,
  PLConfirmDialogService,
} from '@root/index';
import {
  FeatureFlagName,
  FeatureFlagsService,
} from '@root/src/app/common/feature-flags';
import { PLLearnUponLauncherService } from '@root/src/app/modules/launcher/pl-learnupon-launcher/pl-learnupon-launcher.service';
import { User } from '../../user/user.model';

dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);
const updateTaskOwnerQuery = require('./queries/update-task-owner.graphql');

/**
 * This is the Provider Landing page.
 * (aka provider home, provider dashboard)
 */
@Component({
  selector: 'pl-dashboard',
  templateUrl: './pl-dashboard.component.html',
  styleUrls: ['./pl-dashboard.component.less'],
})
export class PLDashboardComponent implements OnInit {
  @ViewChild('content') contentEle: ElementRef;
  @ViewChild('footer') footerEle: ElementRef;

  pageInitialized = false;
  socialLinks: any[] = [];
  showFTE = false;
  supportEmail: String = environment.support_email;
  supportPhone: String = environment.support_phone;
  newDesign = false;
  zendeskSupportLinks = false;
  roomLink =
    'https://presencelearning.helpjuice.com/61244-technical-support-troubleshooting/getting-started-in-the-room';
  scheduleLink =
    'https://presencelearning.helpjuice.com/61244-technical-support-troubleshooting/add-an-event-to-your-calendar';
  learnMoreAboutYourRoomLink =
    'https://presencelearning.helpjuice.com/61244-technical-support-troubleshooting/getting-started-in-the-room';
  learnMoreAboutYourScheduleLink =
    'https://presencelearning.helpjuice.com/61244-technical-support-troubleshooting/add-an-event-to-your-calendar';

  currentUser: any = {};
  accountOwner: any = {};

  apps: any[] = [];
  roomUrl: String;
  public showCEMInfo = false;
  private copied = false;
  private loadingEvents = true;
  private today: String;
  scheduleUrl: String;
  private billingCodes: any[] = [];
  private events: any[] = [];
  private colorClasses: any = {
    Absent: 'student_absence',
    'Work with Clients': 'clients',
    'Documentation and Planning': 'documentation_planning',
    Cancelled: 'cancellation',
    Personal: 'personal',
    Other: 'other',
    'Contract Services': 'contract_services',
  };

  availability = {
    totalHours: 26,
    projectedHours: 0,
    remainingHours: 20,
  };
  showTips = {
    onboarding: false,
    tasks: false,
    availability: false,
    schedule: false,
    room: false,
    cem: false,
  };
  alertTask: any = null;
  alertsToShow = {
    task: '',
  };

  equipmentOrderUrl = '';

  private isW2User: boolean;

  private isMHCProvider: boolean;

  showNewLandingPage: boolean = false;
  hasV2Tasks: boolean = false;
  tasksV2Onboarding: any[] = [];

  constructor(
    private store: Store<AppStore>,
    private plUrls: PLUrlsService,
    private plMay: PLMayService,
    private plHttp: PLHttpService,
    private plGraphQL: PLGraphQLService,
    private Element: ElementRef,
    private router: Router,
    private util: PLUtilService,
    private plModalSvc: PLModalService,
    private activatedRoute: ActivatedRoute,
    private plBillingCodes: PLApiBillingCodesService,
    private plConfirmDialogSvc: PLConfirmDialogService,
    private plLearnUponService: PLLearnUponLauncherService,
    private featureFlagsService: FeatureFlagsService,
    private plProviderService: PLProviderService,
    private plTasksService: PLTasksService,
  ) {}

  ngOnInit(): void {
    this.featureFlagsService
      .isFeatureEnabled(FeatureFlagName.useNewProviderProfile)
      .subscribe(enabled => (this.newDesign = enabled));
    this.featureFlagsService
      .isFeatureEnabled(FeatureFlagName.zendeskSupportLinks)
      .subscribe(enabled => {
        this.zendeskSupportLinks = enabled;
        if (enabled) {
          this.roomLink =
            'https//support.presence.com/hc/en-us/articles/27201588972691-Getting-Started-Therapy-Room';
          this.scheduleLink =
            'https://support.presence.com/hc/en-us/articles/27203670903443-Add-an-Event-to-Your-Calendar';
          this.learnMoreAboutYourRoomLink =
            'https://support.presence.com/hc/en-us/articles/27201588972691-Getting-Started-Therapy-Room';
          this.learnMoreAboutYourScheduleLink =
            'https://support.presence.com/hc/en-us/articles/27203670903443-Add-an-Event-to-Your-Calendar';
        }
      });
    this.setOnboardingV2Tasks();
    this.store
      .select(selectCurrentUserLoaded)
      .pipe(
        filter(loadedUser => loadedUser),
        switchMap(() =>
          forkJoin([
            this.store.select(selectCurrentUser).pipe(first()),
            this.store.select(selectIsW2User).pipe(first()),
          ]),
        ),
      )
      .subscribe(([user, isW2]: [User, boolean]) => {
        this.currentUser = user;
        this.isW2User = isW2;
        this.isMHCProvider =
          user?.xProvider?.providerTypeCode === PLProviderTypeCode.MHP;

        this.setApps();

        if (user.groups) {
          if (!this.plMay.canAccessProviderLanding(user)) {
            this.router.navigateByUrl('/not-found', {
              skipLocationChange: true,
            });
          }

          this.plBillingCodes.get().subscribe((res: any) => {
            this.billingCodes = res;
            this.setSchedule();
          });

          this.setSocialLinks();
          this.pageInitialized = true;

          this.equipmentOrderUrl = `https://www.tfaforms.com/4846346?email=${encodeURIComponent(
            user.email,
          )}`;

          if (this.plMay.isProvider(user)) {
            this.plProviderService
              .getProviderByUserId(user?.uuid)
              .subscribe((provider: any) => {
                if (provider) {
                  if (provider.accountOwner) {
                    this.accountOwner = provider.accountOwner;
                    this.showCEMInfo = true;
                  }
                }
              });
          }
        }
      });

    this.featureFlagsService
      .isFeatureEnabled(FeatureFlagName.showNewLandingPage)
      .subscribe(enabled => {
        this.showNewLandingPage = enabled;
      });
  }

  toggleShowTips(key: string) {
    this.showTips[key] = !this.showTips[key];
  }

  setApps() {
    const apps: {
      icon: string;
      href?: string;
      label: string;
      cssClass: string;
      description: string;
      isNewWindow?: boolean;
      hrefAbsolute?: string;
    }[] = [
      {
        href: this.newDesign
          ? `/provider-profile/${this.currentUser.uuid}/basic-information`
          : `/provider/${this.currentUser.uuid}`,
        icon: 'user',
        label: 'Profile',
        description: 'See your profile.',
        cssClass: 'pl-dashboard-resource-ml',
      },
      {
        hrefAbsolute: 'http://mail.presencelearning.com/',
        icon: 'email-outline',
        label: 'Presence Mail',
        description: 'Check your Presence email.',
        cssClass: 'pl-dashboard-resource-fl',
      },
      {
        hrefAbsolute: this.zendeskSupportLinks
          ? 'https://support.presence.com/'
          : 'https://presencelearning.helpjuice.com/',
        icon: 'people-question',
        label: 'Help Center',
        description: 'Get the help you need.',
        cssClass: 'pl-dashboard-resource-fl',
      },
      {
        href: `${this.plLearnUponService.getDashboardUrl()}`,
        isNewWindow: true,
        icon: 'graduation-hat',
        label: 'Telehealth Institute',
        description: 'Access and complete your training courses.',
        cssClass: 'pl-dashboard-resource-fl',
      },
      {
        hrefAbsolute: 'https://presencelearning.com/lounge',
        icon: 'lounge',
        label: 'Lounge',
        description: 'Communicate with the PL Clinician community.',
        cssClass: 'pl-dashboard-resource-ml',
      },
      {
        hrefAbsolute: 'https://go.ernestco.co/Login/assessmentspl',
        icon: 'online-store',
        label: 'Online Store',
        description: 'Presence Store.',
        cssClass: 'pl-dashboard-resource-sl',
      },
    ];
    this.apps = apps;
    this.roomUrl = `${this.plUrls.urls.roomFE}/${this.currentUser.username}`;
  }

  setSocialLinks() {
    const socialLinks = [
      {
        icon: 'facebook',
        hrefAbsolute: 'https://www.facebook.com/PresenceLearning',
      },
      { icon: 'twitter', hrefAbsolute: 'https://twitter.com/telepractice' },
      {
        icon: 'youtube',
        hrefAbsolute: 'https://www.youtube.com/user/PresenceLearning',
      },
      {
        icon: 'linked-in',
        hrefAbsolute: 'https://www.linkedin.com/company/presencelearning',
      },
    ];
    this.socialLinks = socialLinks;
  }

  handleTask(t: any) {
    const mutations = t.owners
      .filter((o: any) => o.user.id === this.currentUser.uuid && !o.read)
      .map((o: any) =>
        this.plGraphQL.mutate(
          updateTaskOwnerQuery,
          { id: o.id, read: true },
          {},
        ),
      );

    forkJoin(mutations)
      .pipe(defaultIfEmpty([]))
      .subscribe(res => {
        console.log('----- debug newness', res);
        window.location.href = t.actionUrl;
        // window.open(t.actionUrl, '_blank');
      });
  }

  setSchedule() {
    if (this.currentUser.xProvider && this.currentUser.xProvider.timezone) {
      const tz = this.currentUser.xProvider.timezone;
      // get today in local timezone representation
      const now = dayjs();
      const nowLocal = now.tz(tz);

      // get the display string for today in local time
      this.today = nowLocal.format('dddd, MMM D');

      // get the start of today... still in local time
      const startOfToday = nowLocal.startOf('day');

      // get the UTC conversions to use as query start/end date range.
      const schedQueryStart = dayjs
        .utc(startOfToday)
        .add(1, 'seconds')
        .format('YYYY-MM-DDTHH:mm:ss');
      const schedQueryEnd = dayjs
        .utc(startOfToday)
        .add(1, 'days')
        .format('YYYY-MM-DDTHH:mm:ss');

      this.plHttp
        .get('appointments', {
          event_type__in: 'BILLING',
          provider: this.currentUser.uuid,
          calendar_view: true,
          start: schedQueryStart,
          end: schedQueryEnd,
        })
        .subscribe((res: any) => {
          const results = res.results;
          results.forEach((e: any) => {
            e.billing_expanded = this.plBillingCodes.getFromKey(
              'uuid',
              e.billing_code,
            );
            // PL-514: adjust appt display time for repeating event DST crossover
            const apptInfo = this.util.computeAppointmentLocalDateTimes(e, tz);
            const localStart = (e.start = apptInfo.apptStart);
            const localEnd = (e.end = apptInfo.apptEnd);
            e.time = `${localStart.format('h:mma')}-${localEnd.format(
              'h:mma',
            )}`;

            // generate lists of comma-separated names and/or locations to avoid extra weight in rendering
            e.clientList = e.clients
              .map((c: any) => `${c.first_name} ${c.last_name}`)
              .join(', ');
            e.locationList = e.locations
              .map((l: any) => `${l.name}`)
              .join(', ');
            // add isPast flag to style past appointments
            if (dayjs(e.original_start).isBefore(dayjs())) {
              e.isPast = true;
            }
          });
          results.sort((a: any, b: any) => {
            return (
              dayjs.utc(dayjs.tz(a.start, tz)).valueOf() -
              dayjs.utc(dayjs.tz(b.start, tz)).valueOf()
            );
          });
          // find and mark last past appointment
          const i =
            results.length -
            1 -
            results
              .slice()
              .reverse()
              .findIndex((e: any) => e.isPast === true);
          if (i >= 0 && results[i]) {
            results[i].lastPast = true;
          }
          this.events = results;
          this.loadingEvents = false;
          // scroll last past appointment into view with a slight delay to allow for availability
          setTimeout(() => {
            const lP = this.Element.nativeElement.querySelector('.past.last');
            if (lP) {
              // use scrollTop instead of scrollIntoView because of parent scrolling behavior
              lP.parentNode.parentNode.scrollTop =
                lP.offsetTop - lP.parentNode.parentNode.offsetTop;
            }
          }, 100);
        });
    } else {
      this.events = [];
      this.loadingEvents = false;
    }
  }

  getColorClass(e: any) {
    if (e.billing_expanded && e.billing_expanded.event_category) {
      return this.colorClasses[e.billing_expanded.event_category.name];
    }
    return 'other';
  }

  copyRoomUrl() {
    const el = document.getElementById('room-url') as HTMLInputElement;
    el.select();
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    this.copied = true;
    window.setTimeout(() => {
      this.copied = false;
    }, 3000);
  }

  isCopied = function () {
    return this.copied;
  };

  onClickSupportChat() {
    const zenDeskwidget = window['zE'];
    this.plHttp
      .get('', {}, this.plUrls.urls.gatewayZendeskTokenCreation)
      .subscribe((resp: any) => {
        if (resp.exists_in_zendesk) {
          zenDeskwidget('messenger', 'loginUser', function (callback) {
            callback(resp.jwt);
          });
          zenDeskwidget('messenger:set', 'conversationFields', [
            { id: '30009313905171', value: this.currentUser.email },
            {
              id: '26287517467923',
              value: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
            },
          ]);
        }
        zenDeskwidget('messenger', 'show');
        zenDeskwidget('messenger', 'open');
      });
  }

  setOnboardingV2Tasks() {
    this.plTasksService
      .getTasks()
      .pipe(first())
      .subscribe((res: any) => {
        let v2onboarding = [];

        if (res.tasks?.length) {
          res.tasks.forEach((task: any) => {
            if (task.taskType.code.includes('v2onboarding')) {
              let isComplete = false;
              for (let ii = 0; ii < task.owners.length; ii++) {
                if (task.owners[ii].user.id === this.currentUser.uuid) {
                  isComplete = task.owners[ii].isComplete;
                  break;
                }
              }
              v2onboarding.push(
                Object.assign(task, {
                  isComplete,
                }),
              );
            }
          });

          if (v2onboarding.length > 0) {
            const incompleteTasks = v2onboarding.filter(task => {
              return !task.isComplete;
            });

            if (incompleteTasks.length > 0) {
              this.tasksV2Onboarding = v2onboarding;
            }
          }
        }
      });
  }
}
